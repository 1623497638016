import React from "react";
import {Link} from 'gatsby';
import Layout from "../components/Layout";
import SEO from "../components/seo";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="bg-light p-4">
    <h1>NOT FOUND</h1>
    <p>We don't have a page at this location quite yet. </p>
    <p>Visit our <Link to="/">home page</Link> to see what we offer.</p>
    </div>
  </Layout>
);

export default NotFoundPage;
